import {
  useVideoConfig,
  useCurrentFrame,
  Sequence,
  Audio,
  staticFile,
  AbsoluteFill,
  Img,
} from "remotion";
import { WorkoutSequence } from "../Composition";
import { Animated, Fade, Move, Rotate, Scale } from "remotion-animated";
import { PauseIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { loadFont } from "@remotion/google-fonts/BarlowSemiCondensed";
import { LoopedOffthreadVideo } from "./LoopedOffthreadVideo";
import { formatSeconds } from "src/global/utils/format-seconds.util";
import { useEffect, useRef, useState } from "react";
import { Channels, Workouts } from "src/global/types/directus";

const { fontFamily } = loadFont();

export type ExerciseSequencesProps = {
  workout: Workouts;
  sequences: WorkoutSequence[];
  introDurationInSeconds: number;
  outroDurationInSeconds: number;
  isCountdownMale: boolean;
};

/**
 * Static assets
 */
const tickSound = staticFile("workouts/tick.wav");
const beepSound = staticFile("workouts/beep.wav");
const cdMale = staticFile("workouts/cdmale.wav");
const cdFemale = staticFile("workouts/cdfemale.wav");
const shapeSvg = staticFile("workouts/shape.svg");

/**
 * Utils
 */
const getRepsCountdown = (
  sequence: WorkoutSequence,
  currentFrame: number,
  fps: number
): { count: number; reps: number } => {
  const currentSecond =
    (currentFrame - sequence.startFrame) / fps -
    (sequence.workoutExercise.prepare_duration as number);
  const duration = sequence.workoutExercise.duration || 60;
  const reps = sequence.workoutExercise.reps || 12;
  const unitDurationInSeconds = duration / reps;
  const count = Math.ceil(currentSecond / unitDurationInSeconds);

  return {
    count: count > 0 ? (count <= reps ? count : reps) : 0,
    reps: reps,
  };
};

export const ExerciseSequences = ({
  workout,
  sequences,
  introDurationInSeconds,
  outroDurationInSeconds,
  isCountdownMale,
}: ExerciseSequencesProps) => {
  const { fps } = useVideoConfig();
  const currentFrame = useCurrentFrame();
  const [exerciseCount, setExerciceCount] = useState<number>();
  const [logoStaticPath, setLogoStaticPath] = useState<string>();
  const [brandColorClass, setBrandColorClass] = useState("bg-red-500");

  useEffect(() => {
    let eCount = 0;

    sequences.forEach((sequence) => {
      if (sequence.workoutExercise.is_reps) {
        if (sequence.setsItemIndex === 0) {
          eCount++;
        }
      } else {
        eCount++;
      }
    });

    setExerciceCount(eCount);

    switch ((workout.channel as Channels).channel_id) {
      case "WORKOUT-PATH":
        setBrandColorClass("bg-red-500");
        setLogoStaticPath("workouts/workoutpath/logo.svg");
        break;
      case "HomeGymMen":
        setBrandColorClass("bg-home-gym-men-blue");
        setLogoStaticPath("workouts/homegymmen/logo.svg");
        break;
      case "HomeGymWomen":
        setBrandColorClass(
          "bg-gradient-to-r from-home-gym-women-pink to-home-gym-women-purple"
        );
        setLogoStaticPath("workouts/homegymwomen/logo.svg");
        break;
      default:
        setLogoStaticPath("workouts/workoutpath/logo.svg");
    }
  }, []);

  const visibleSequences: number[] = [];

  if (sequences && sequences.length > 0) {
    sequences.forEach((sequence, index) => {
      const duration = sequence.workoutExercise.duration || 60;

      const sequenceDurationFrames = Math.floor(
        fps * (duration + (sequence.workoutExercise.prepare_duration as number))
      );

      if (
        currentFrame >= sequence.startFrame - introDurationInSeconds * fps &&
        currentFrame <=
          sequence.startFrame +
            sequenceDurationFrames +
            outroDurationInSeconds * fps
      ) {
        visibleSequences.push(index);

        if (index >= 1) {
          visibleSequences.push(index - 1);
        }

        if (index < sequences.length - 1) {
          visibleSequences.push(index + 1);
        }
      }
    });
  }

  return (
    <>
      {sequences.map((sequence, key) => {
        const duration = sequence.workoutExercise.duration || 60;
        const sequenceDurationFrames = Math.floor(
          fps *
            (duration + (sequence.workoutExercise.prepare_duration as number))
        );
        const transitionDuration = fps;

        // progress
        const progressFrames =
          currentFrame -
          sequence.startFrame -
          Math.floor(
            (sequence.workoutExercise.prepare_duration as number) * fps
          );
        const progressPercentage =
          ((Math.floor(duration * fps) - progressFrames) /
            Math.floor(duration * fps)) *
          100;
        const progressPercentageReverse = 100 - progressPercentage;
        const progressSeconds = Math.floor(
          (Math.floor(duration * fps) - progressFrames) / fps
        );
        const pauseProgressSeconds = Math.floor(
          Math.floor(
            Math.floor(duration * fps) -
              progressFrames -
              Math.floor(duration * fps)
          ) / fps
        );
        const pauseProgressFrames =
          Math.floor(
            (sequence.workoutExercise.prepare_duration as number) * fps
          ) -
          (currentFrame - sequence.startFrame);

        const pauseProgressPercentage =
          (pauseProgressFrames /
            Math.floor(
              (sequence.workoutExercise.prepare_duration as number) * fps
            )) *
          100;

        const exercisePrepareFrame = 0 * fps;
        const exerciseStartFrame =
          (sequence.workoutExercise.prepare_duration as number) * fps;
        const exerciseEndFrame =
          (sequence.workoutExercise.prepare_duration as number) +
          duration * fps;

        const repsCountdown = sequence.workoutExercise.is_reps
          ? getRepsCountdown(sequence, currentFrame, fps)
          : null;

        const staticStartFrame = sequence.startFrame;
        const staticEndFrame =
          sequence.startFrame + sequence.startFrame + sequenceDurationFrames;
        const isInCurrentFrameRange =
          currentFrame > staticStartFrame && currentFrame < staticEndFrame;

        const currentSetSequence =
          sequence.setsItemIndex !== undefined &&
          sequence.setsItemIndex !== null &&
          sequence.setsItemIndex + 1;

        return (
          <AbsoluteFill key={sequence.startFrame}>
            {visibleSequences.includes(key) && (
              <Animated
                absolute
                style={{ width: "100%", height: "100%" }}
                key={sequence.startFrame}
                animations={[
                  Fade({
                    to: 1,
                    initial: 0,
                    start: sequence.startFrame,
                    duration: 30,
                  }),
                  Move({
                    x: 0,
                    initialX: 4000,
                    start: sequence.startFrame,
                    duration: transitionDuration,
                  }),
                  Move({
                    x: -4000,
                    initialX: 0,
                    start: sequence.startFrame + sequenceDurationFrames,
                    duration: transitionDuration,
                  }),
                ]}
              >
                <Sequence
                  from={sequence.startFrame}
                  durationInFrames={sequenceDurationFrames + transitionDuration}
                  className="bg-white"
                >
                  <Animated
                    absolute
                    className="top-0 left-0 w-full h-full"
                    style={{ transform: "translateX(450px) scale(0.5)" }}
                    animations={[
                      // on start
                      Move({
                        x: 0,
                        initialX: 450,
                        start: exerciseStartFrame,
                        duration: 30,
                      }),
                      Scale({
                        by: 0.75,
                        initial: 0.5,
                        start: exerciseStartFrame,
                        duration: 30,
                      }),

                      // slow zoom in
                      Scale({
                        by: 1.25,
                        initial: 1,
                        start: exerciseStartFrame + fps,
                        duration: (duration / 2) * fps,
                        mass: 75,
                      }),
                      Move({
                        y: -150,
                        initialY: 0,
                        start: exerciseStartFrame + fps,
                        duration: (duration / 2) * fps,
                      }),

                      // slow zoom out
                      Scale({
                        by: 0.75,
                        initial: 1,
                        start: exerciseStartFrame + fps + (duration / 2) * fps,
                        duration: (duration / 2) * fps,
                        mass: 75,
                      }),
                      Move({
                        y: 150,
                        initialY: 0,
                        start: exerciseStartFrame + fps + (duration / 2) * fps,
                        duration: (duration / 2) * fps,
                      }),
                    ]}
                  >
                    <Animated
                      animations={[
                        Fade({
                          to: 0,
                          initial: 1,
                          start: Math.floor(
                            ((sequence.workoutExercise
                              .prepare_duration as number) +
                              duration -
                              1) *
                              fps
                          ),
                          duration: fps,
                        }),
                      ]}
                    >
                      <Img
                        src={staticFile("workouts/room.png")}
                        style={{
                          width: "100%",
                          position: "absolute",
                          top: "0",
                          left: 0,
                          transform: "translate(0%, 65%) scale(3)",
                        }}
                      ></Img>

                      <AbsoluteFill className="w-full h-full flex justify-center items-center text-white opacity-30">
                        {logoStaticPath && (
                          <Img
                            style={{
                              width: "1000px",
                              opacity: ".8",
                              transform:
                                "scale(8) translateY(20%) perspective(500px) rotateX(45deg)",
                              transformOrigin: "bottom",
                            }}
                            src={staticFile(logoStaticPath)}
                          />
                        )}
                      </AbsoluteFill>

                      <LoopedOffthreadVideo
                        src={sequence.prefetchedUrl}
                        flip={!!sequence.workoutExercise.flip}
                        playbackRate={
                          sequence.workoutExercise.playback as number
                        }
                      />
                    </Animated>
                  </Animated>

                  {/* Item Elements */}
                  <AbsoluteFill>
                    {/* BG-Shape */}
                    <Animated
                      absolute
                      style={{
                        top: 0,
                        width: "100%",
                        height: "100%",
                        left: 0,
                      }}
                      in={0}
                      animations={[
                        Fade({
                          to: 1,
                          initial: 0,
                          duration: fps,
                          start: 0,
                        }),
                        Scale({
                          by: 0.75,
                          initial: 1,
                          start: exerciseStartFrame,
                        }),
                        Move({
                          x: -500,
                          y: -500,
                          initialX: 0,
                          initialY: 0,
                          start: exerciseStartFrame,
                        }),
                      ]}
                    >
                      <Img
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "-1600px",
                          width: "100%",
                        }}
                        src={shapeSvg}
                        alt=""
                      />
                    </Animated>

                    {/* SUBSCRIBE */}
                    <Animated
                      absolute
                      animations={[
                        Fade({
                          to: 0,
                          initial: 1,
                          duration: fps,
                          start: exerciseStartFrame,
                        }),
                      ]}
                      style={{
                        fontFamily,
                        fontWeight: "600",
                        fontSize: "100px",
                        position: "absolute",
                        bottom: "314px",
                        right: "200px",
                        color: "#fff",
                        backgroundColor: "#ef4445",
                        padding: "15px 80px",
                        borderRadius: "100px",
                      }}
                    >
                      SUBSCRIBE
                    </Animated>

                    {/* Description */}
                    <Animated
                      absolute
                      animations={[
                        Scale({
                          by: 0.75,
                          initial: 1,
                          start: exerciseStartFrame,
                        }),
                      ]}
                    >
                      <AbsoluteFill
                        style={{
                          top: 0,
                          width: "100%",
                          height: "100%",
                          left: "0",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "300px",
                            left: "213px",
                            width: "1200px",
                          }}
                        >
                          <div>
                            <div
                              style={{
                                borderRadius: "100px",
                                overflow: "hidden",
                                width: "1250px",
                                position: "absolute",
                              }}
                            >
                              <Animated
                                absolute
                                animations={[
                                  Fade({
                                    to: 0,
                                    initial: 1,
                                    duration: fps,
                                    start: exerciseStartFrame,
                                  }),
                                  Move({
                                    x: -750,
                                    y: 0,
                                    initialY: 0,
                                    initialX: 0,
                                    start: exerciseStartFrame,
                                  }),
                                ]}
                                className="text-white bg-black px-8 py-4"
                                style={{
                                  fontFamily,
                                  fontWeight: 500,
                                  fontSize: "200px",
                                  borderRadius: "100px",
                                  padding: "25px 125px",
                                  position: "absolute",
                                  top: 0,
                                  left: "500px",
                                  paddingLeft: "250px",
                                }}
                              >
                                NEXT
                              </Animated>
                              <AbsoluteFill></AbsoluteFill>
                              <div
                                className={`text-white ${brandColorClass} px-8 py-4 text-center`}
                                style={{
                                  fontFamily,
                                  fontWeight: 500,
                                  fontSize: "200px",
                                  borderRadius: "100px",
                                  padding: "25px 125px",
                                  position: "relative",
                                  width: "700px",
                                  /* textShadow: "0px 0px 5px black", */
                                }}
                              >
                                {sequence.count}/{exerciseCount}
                              </div>
                            </div>

                            <div
                              className=" text-gray-800"
                              style={{
                                fontFamily,
                                fontWeight: 600,
                                paddingLeft: "25px",
                                fontSize: "150px",
                                maxWidth: "1200px",
                                textTransform: "uppercase",
                                lineHeight: "1.2",
                                position: "absolute",
                                top: "420px",
                              }}
                            >
                              {sequence.workoutExercise.title}
                            </div>
                          </div>
                        </div>
                      </AbsoluteFill>
                    </Animated>

                    {/* PROGRESS */}
                    {pauseProgressSeconds > 0 ? (
                      <Animated
                        absolute
                        style={{
                          top: 0,
                          width: "100%",
                          height: "100%",
                          left: "0",
                        }}
                        animations={[]}
                      >
                        <div
                          style={{
                            width: "1150px",
                            left: "213px",
                            bottom: "318px",
                            position: "absolute",
                          }}
                          className="h-24 bg-white rounded-full overflow-hidden"
                        >
                          <div
                            className="h-24 bg-black rounded-full"
                            style={{ width: `${pauseProgressPercentage}%` }}
                          ></div>
                        </div>

                        <div
                          style={{
                            fontFamily,
                            left: "213px",
                            bottom: "360px",
                            position: "absolute",
                            fontSize: "340px",
                            color: "#000",
                            fontWeight: "600",
                          }}
                        >
                          <div className="flex items-end">
                            <PauseIcon className="w-80 pb-32 -mb-8 pr-8 text-black"></PauseIcon>
                            {formatSeconds(pauseProgressSeconds)}
                          </div>
                        </div>
                      </Animated>
                    ) : (
                      <Animated
                        absolute
                        style={{
                          top: 0,
                          width: "100%",
                          height: "100%",
                          left: "0",
                        }}
                        animations={[
                          Move({
                            y: 1000,
                            initialY: 0,
                            duration: fps,
                            start: Math.floor(
                              ((sequence.workoutExercise
                                .prepare_duration as number) +
                                duration -
                                1) *
                                fps
                            ),
                          }),
                          Fade({
                            to: 0,
                            initial: 1,
                            start: Math.floor(
                              ((sequence.workoutExercise
                                .prepare_duration as number) +
                                duration -
                                1) *
                                fps
                            ),
                            duration: fps,
                          }),
                        ]}
                      >
                        <div className="absolute top left w-full h-full">
                          <Animated
                            absolute
                            style={{
                              left: "0",
                              bottom: "0",
                            }}
                            animations={[
                              Scale({
                                by: 0.75,
                                initial: 1,
                                start: exerciseStartFrame,
                              }),
                            ]}
                          >
                            {sequence.workoutExercise.is_reps ? (
                              <div
                                style={{
                                  left: "213px",
                                  bottom: "250px",
                                  position: "absolute",
                                }}
                                className="flex rounded-full items-center p-6 flex-row"
                              >
                                {Array.from(
                                  Array(sequence.workoutExercise.sets).keys()
                                ).map((item) => {
                                  const progress =
                                    item === sequence.setsItemIndex
                                      ? progressPercentageReverse > 0
                                        ? progressPercentageReverse
                                        : 0
                                      : sequence.setsItemIndex !== null &&
                                        sequence.setsItemIndex !== undefined &&
                                        item >= sequence.setsItemIndex
                                      ? 0
                                      : 100;

                                  const progressValue =
                                    item === sequence.setsItemIndex
                                      ? repsCountdown?.count
                                      : sequence.setsItemIndex !== null &&
                                        sequence.setsItemIndex !== undefined &&
                                        item >= sequence.setsItemIndex
                                      ? 0
                                      : sequence.workoutExercise.reps;
                                  const isActiveItem =
                                    item === sequence.setsItemIndex;
                                  const isPrevItem =
                                    sequence.setsItemIndex !== null &&
                                    sequence.setsItemIndex !== undefined &&
                                    item <= sequence.setsItemIndex;
                                  const isLastItem =
                                    item + 1 === sequence.workoutExercise.sets;

                                  const style =
                                    item === sequence.setsItemIndex
                                      ? {
                                          "--value": progress,
                                          "--size": "36rem",
                                          "--thickness": "3.5rem",
                                          fontFamily,
                                          fontSize: "150px",
                                          fontWeight: 500,
                                        }
                                      : sequence.setsItemIndex !== null &&
                                        sequence.setsItemIndex !== undefined &&
                                        item >= sequence.setsItemIndex
                                      ? {
                                          "--value": 0,
                                          "--size": "17rem",
                                          "--thickness": "2rem",
                                          fontFamily,
                                          fontSize: "60px",
                                          fontWeight: 500,
                                        }
                                      : {
                                          "--value": 100,
                                          "--size": "17rem",
                                          "--thickness": "2rem",
                                          fontFamily,
                                          fontSize: "60px",
                                          fontWeight: 500,
                                        };

                                  return (
                                    <div key={item}>
                                      {isActiveItem ? (
                                        <>
                                          <div
                                            className={`radial-progress drop-shadow-2xl ${
                                              isActiveItem
                                                ? "bg-white"
                                                : "bg-gray-300"
                                            }  bg-opacity-50 ${
                                              isActiveItem
                                                ? "text-red-500"
                                                : isPrevItem
                                                ? "text-gray-400"
                                                : "text-gray-400"
                                            } ${isLastItem ? "" : "mr-24"}`}
                                            style={style}
                                          >
                                            <div>
                                              <div className="flex items-end">
                                                {(progressValue &&
                                                  repsCountdown &&
                                                  progressValue <
                                                    repsCountdown.reps) ||
                                                isActiveItem
                                                  ? `${progressValue}/${repsCountdown?.reps}`
                                                  : repsCountdown?.reps}
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className={`flex text-center drop-shadow-2xl rounded-full items-center justify-center ${
                                              !isPrevItem
                                                ? "bg-gray-100 text-gray-500"
                                                : `${brandColorClass} text-gray-100`
                                            } ${isLastItem ? "" : "mr-24"}`}
                                            style={{
                                              fontFamily,
                                              fontSize: "100px",
                                              fontWeight: 500,
                                              width: "400px",
                                              height: "400px",
                                            }}
                                          >
                                            <div className="flex items-end">
                                              {progressValue ===
                                              sequence.workoutExercise.reps
                                                ? progressValue
                                                : `${progressValue}/${repsCountdown?.reps}`}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: "1150px",
                                  left: "213px",
                                  bottom: "318px",
                                  position: "absolute",
                                }}
                                className="h-24 bg-white rounded-full overflow-hidden"
                              >
                                <div
                                  className={`h-24 ${brandColorClass} rounded-full`}
                                  style={{
                                    width: `${
                                      progressPercentageReverse > 0
                                        ? progressPercentageReverse
                                        : 0
                                    }%`,
                                  }}
                                ></div>
                              </div>
                            )}
                          </Animated>
                        </div>

                        <div className="absolute top left w-full h-full">
                          <Animated
                            absolute
                            style={{
                              left: "0",
                              bottom: "0",
                            }}
                            animations={[
                              Scale({
                                by: 0.75,
                                initial: 1,
                                start: exerciseStartFrame,
                              }),
                            ]}
                          >
                            <div
                              style={{
                                fontFamily,
                                left: "213px",
                                bottom: "360px",
                                position: "absolute",
                                fontSize: "340px",
                                color:
                                  progressSeconds <= 5 ? "#ee3130" : "#000",
                                fontWeight: "600",
                              }}
                            >
                              {repsCountdown ? (
                                <div className="flex items-end">
                                  {/* <RefreshIcon className="w-80 pb-32 -mb-8 pr-8 text-black"></RefreshIcon>{" "}
                                  {repsCountdown?.count}/{repsCountdown?.reps} */}
                                </div>
                              ) : (
                                <div className="flex items-end">
                                  {formatSeconds(progressSeconds)}
                                </div>
                              )}
                            </div>
                          </Animated>
                        </div>
                      </Animated>
                    )}

                    {/* CHECK */}
                    <Animated
                      in={Math.floor(
                        ((sequence.workoutExercise.prepare_duration as number) +
                          duration -
                          1) *
                          fps
                      )}
                      absolute
                      className="top-0 left-0 w-full h-full justify-center items-center flex"
                      animations={[
                        Move({
                          y: 0,
                          initialY: -200,
                          duration: fps,
                          start: Math.floor(
                            ((sequence.workoutExercise
                              .prepare_duration as number) +
                              duration -
                              1) *
                              fps
                          ),
                        }),
                        Fade({
                          to: 1,
                          initial: 0,
                          start: Math.floor(
                            ((sequence.workoutExercise
                              .prepare_duration as number) +
                              duration -
                              1) *
                              fps
                          ),
                        }),
                      ]}
                    >
                      <div
                        style={{
                          position: "absolute",
                          left: "calc(50% - 300px)",
                          bottom: "calc(50% - 300px)",
                        }}
                      >
                        <CheckCircleIcon
                          className=" text-black drop-shadow-2xl"
                          style={{ width: "600px", height: "600px" }}
                        ></CheckCircleIcon>
                      </div>
                    </Animated>

                    {/* SOUNDS */}
                    <Sequence
                      from={0}
                      durationInFrames={
                        (sequence.workoutExercise.prepare_duration as number) *
                        fps
                      }
                    >
                      <Audio loop src={tickSound} />
                    </Sequence>
                    <Sequence
                      from={
                        ((sequence.workoutExercise.prepare_duration as number) -
                          4) *
                        fps
                      }
                      durationInFrames={4 * fps}
                    >
                      <Audio src={isCountdownMale ? cdMale : cdFemale} />
                    </Sequence>

                    <Sequence
                      from={
                        ((sequence.workoutExercise.prepare_duration as number) +
                          duration -
                          6) *
                        fps
                      }
                      durationInFrames={7 * fps}
                    >
                      <Audio src={beepSound} />
                    </Sequence>
                  </AbsoluteFill>
                </Sequence>
              </Animated>
            )}
          </AbsoluteFill>
        );
      })}
    </>
  );
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }

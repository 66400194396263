import {
  useVideoConfig,
  useCurrentFrame,
  Sequence,
  Audio,
  interpolate,
} from "remotion";
import { AudioSequence, WorkoutCompositionProps } from "../Composition";
import { useEffect, useState } from "react";

export type LoopedAudioSequencesProps = {
  props: WorkoutCompositionProps;
  audioSequences: AudioSequence[];
  durationInFrames: number;
};

export const LoopedAudioSequences = ({
  props,
  audioSequences,
  durationInFrames,
}: LoopedAudioSequencesProps) => {
  const { fps } = useVideoConfig();
  const currentFrame = useCurrentFrame();
  const [playList, setPlayList] = useState<AudioSequence[]>([]);

  useEffect(() => {
    const playListTemp: AudioSequence[] = [];
    let playListDurationInSeconds = 0;

    // wie lange dauert das Video?
    const fullDurationInSeconds = props.durationInSeconds;

    while (playListDurationInSeconds < fullDurationInSeconds) {
      audioSequences.forEach((audioSequence) => {
        if (playListDurationInSeconds < fullDurationInSeconds) {
          const startSecond = playListTemp.reduce((acc, val) => {
            return acc + val.musicMetaData.durationInSeconds;
          }, 0);
          const startFrame = Math.floor(startSecond * fps);

          playListTemp.push({
            ...audioSequence,
            startFrame: startFrame,
          });

          playListDurationInSeconds = playListTemp.reduce((acc, val) => {
            return acc + val.musicMetaData.durationInSeconds;
          }, 0);
        }
      });
    }

    setPlayList(playListTemp);
    // wieviele Audios gibt es?
  }, []);

  if (playList.length === 0 || audioSequences.length === 0) {
    return null;
  }

  const visibleMusicSequenceIndexes: number[] = [];

  if (playList && playList.length > 0) {
    playList.forEach((playListSequence, index) => {
      const sequenceDurationFrames = Math.floor(
        30 * playListSequence.musicMetaData.durationInSeconds
      );

      if (
        currentFrame >= playListSequence.startFrame &&
        currentFrame <= playListSequence.startFrame + sequenceDurationFrames
      ) {
        visibleMusicSequenceIndexes.push(index);

        // not needed for music, because it is not overlapped
        /* if (index >= 1) {
          visibleMusicSequenceIndexes.push(index - 1);
        }

        if (index < audioSequences.length - 1) {
          visibleMusicSequenceIndexes.push(index + 1);
        } */
      }
    });
  }

  return (
    <div>
      {playList.map((audioSequence, key) => {
        return (
          visibleMusicSequenceIndexes.includes(key) && (
            <Sequence
              name={audioSequence.audio.name}
              key={key}
              from={audioSequence.startFrame}
              durationInFrames={Math.floor(
                audioSequence.musicMetaData.durationInSeconds * 30
              )}
            >
              <Audio
                volume={(f) =>
                  interpolate(
                    f,
                    [0, 3 * 30, durationInFrames - 7 * 30, durationInFrames],
                    [0, 1, 1, 0],
                    { extrapolateLeft: "clamp", extrapolateRight: "clamp" }
                  )
                }
                src={audioSequence.prefetchedUrl}
              />
            </Sequence>
          )
        );
      })}
    </div>
  );
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }

import { getVideoMetadata } from "@remotion/media-utils";
import { useEffect, useState } from "react";
import {
  cancelRender,
  continueRender,
  delayRender,
  Loop,
  OffthreadVideo,
  useVideoConfig,
} from "remotion";

export type LoopedOffthredVideoProps = {
  src: string;
  flip?: boolean;
  playbackRate: number | null;
}

export const LoopedOffthreadVideo = ({
  src,
  flip,
  playbackRate,
}: LoopedOffthredVideoProps) => {
  const [durationInFrames, setDurationInFrames] = useState<null | number>(null);
  const [playbackRateState, setPlaybackRateState] = useState<null | number>(
    null
  );
  const [handle] = useState(() => delayRender());
  const { fps } = useVideoConfig();

  useEffect(() => {
    getVideoMetadata(src)
      .then(({ durationInSeconds }) => {
        const playbackWithFallback = playbackRate || 2;
        const roundedDurationInSeconds = Math.round(durationInSeconds);
        const playbackRateFromRounded =
          (playbackWithFallback / durationInSeconds) * roundedDurationInSeconds;

        console.log(`durationInSeconds: ${durationInSeconds}`);
        console.log(`playbackRate: ${playbackWithFallback}`);

        console.log(`roundedDurationInSeconds: ${roundedDurationInSeconds}`);
        console.log(`playbackRateFromRounded: ${playbackRateFromRounded}`);

        const durationSeconds = durationInSeconds / playbackRateFromRounded;

        const durationFrames = Math.floor(durationSeconds * fps);
        const playback = playbackRateFromRounded;

        console.log(`Final Duration in frames: ${durationFrames}`);
        console.log(`Final Playback Rate: ${playback}`);
        setPlaybackRateState(playback);
        setDurationInFrames(durationFrames);

        continueRender(handle);
      })
      .catch((err) => {
        console.log(err);
        cancelRender(handle);
      });
  }, []);

  if (durationInFrames === null || playbackRateState === null) {
    return null;
  }

  return (
    <Loop durationInFrames={durationInFrames}>
      <OffthreadVideo
        transparent
        src={src}
        style={{
          width: "100%",
          height: "100%",
          transform: flip ? "scaleX(-1)" : "none",
        }}
        playbackRate={playbackRateState}
      />
    </Loop>
  );
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }

import {
  AbsoluteFill,
  prefetch,
  delayRender,
  continueRender,
  cancelRender,
  useVideoConfig,
  Sequence,
  OffthreadVideo,
} from "remotion";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  getVideoMetadata,
  VideoMetadata,
  getAudioData,
  AudioData,
} from "@remotion/media-utils";
import { LoopedAudioSequences } from "./components/LoopedAudioSequences";
import { ExerciseSequences } from "./components/ExerciseSequences";
import { WorkoutService } from "@/remotion/services/workout.service";
import { Animated, Fade } from "remotion-animated";
import { getPlayback } from "@/utils/get-playback.util";
import { WorkoutExercises, Workouts } from "src/global/types/directus";
import { InputProps } from "@/remotion/Root";

export type WorkoutSequence = {
  workoutExercise: WorkoutExercises;
  prefetchedUrl: string;
  nativeUrl: string;
  videoMetaData: VideoMetadata;
  startFrame: number;
  setsItemIndex?: number;
  count?: number;
};

export type AudioSequence = {
  audio: any;
  prefetchedUrl: string;
  nativeUrl: string;
  musicMetaData: AudioData;
  startFrame: number;
};

export type VideoSequence = {
  prefetchedUrl: string;
  startFrame: number;
  durationInSeconds: number;
};

export type WorkoutCompositionProps = InputProps & {
  durationInSeconds: number;
  durationInFrames: number; // important for init in root
  introPath: string | null;
  outroPath: string | null;
  workout: Workouts;
};

export const WorkoutComposition = (props: WorkoutCompositionProps) => {
  const [introSequence, setIntroSequence] = useState<VideoSequence>();
  const [outroSequence, setOutroSequence] = useState<VideoSequence>();
  const [sequences, setSequences] = useState<WorkoutSequence[]>([]);
  const [audioSequences, setMusicSequences] = useState<AudioSequence[]>([]);
  const [unmountCallbacks, setUnmountCallbacks] = useState<(() => void)[]>();
  const [unmountAudioCallbacks, setUnmountAudioCallbacks] =
    useState<(() => void)[]>();
  const [musicLoopDurationInSeconds, setMusicLoopDurationInSeconds] =
    useState(0);
  const [workoutService, setWorkoutService] = useState<WorkoutService>();
  const [handleVideoInitialize] = useState(() =>
    delayRender("Initialize Videos")
  );
  const [handleAudioInitialize] = useState(() =>
    delayRender("Initialize Videos")
  );
  const { fps } = useVideoConfig();

  const isAudio = useMemo(() => {
    return props.workout?.music && props.workout.music.length > 0;
  }, []);

  /**
   * Prefetch function
   */
  const initializeAudioSequences = useCallback(async (Workout: Workouts) => {
    if (!isAudio) {
      console.log("No audio");
      continueRender(handleAudioInitialize);
      return;
    }

    try {
      const unmountAudioCallbacksCollection: (() => void)[] = [];
      const preparedAudioItems: AudioSequence[] = [];

      const WorkoutMusicList = Workout.music;

      if (WorkoutMusicList && WorkoutMusicList.length > 0) {
        for await (const [index, mFile] of WorkoutMusicList.entries()) {
          const workoutMusicId = mFile?.music_file;
          const url = `https://api.pathura.com/assets/${workoutMusicId}?access_token=${props.access_token}`;

          const { free, waitUntilDone } = prefetch(url, {
            method: "blob-url",
          });

          try {
            const blobUrl = await waitUntilDone();
            const musicMetaData = await getAudioData(blobUrl).catch((error) => {
              console.log(error);
            });

            const preparedSet = [
              {
                audio: mFile,
                prefetchedUrl: blobUrl,
                nativeUrl: url,
                startFrame: 0,
                musicMetaData: musicMetaData,
              },
            ];

            preparedAudioItems.push(...(preparedSet as any));
            unmountAudioCallbacksCollection.push(free);
          } catch (error) {
            console.log({
              message: "Error during waitUntilDone initializeAudioSequences",
              log: error,
            });
            console.error(
              `Error during waitUntilDone initializeAudioSequences: ${error}`
            );
          }
        }
      }

      preparedAudioItems.forEach((p, index) => {
        preparedAudioItems[index].startFrame = preparedAudioItems
          .slice(0, index)
          .reduce((acc, curr) => {
            const audioSequenceDurationInSeconds =
              curr.musicMetaData.durationInSeconds;
            return Math.floor(acc + audioSequenceDurationInSeconds * fps);
          }, 0);
      });

      /**
       * Calculate how long the music loop is with all the music sequences combined
       */
      const musicLoopDurationInSeconds = preparedAudioItems.reduce(
        (acc, curr) => acc + curr.musicMetaData.durationInSeconds,
        0
      );

      /**
       * Set states
       */
      setMusicSequences(preparedAudioItems);
      setMusicLoopDurationInSeconds(musicLoopDurationInSeconds);
      setUnmountAudioCallbacks(unmountAudioCallbacksCollection);

      console.log({ message: "Audio sequences initialized", log: undefined });
      continueRender(handleAudioInitialize);
    } catch (error) {
      console.log({
        message: "Error in initializeAudioSequences, rendering cancel",
        log: error,
      });
      cancelRender(error);
    }
  }, []);
  const initializeSequences = useCallback(async (workout: Workouts) => {
    try {
      const unmountCallbacksCollection: (() => void)[] = [];
      const preparedItems: WorkoutSequence[] = [];

      if (
        !workout ||
        !workout.workout_exercises ||
        workout.workout_exercises.length === 0
      ) {
        throw new Error("No exercises to initialize");
      }

      for await (const [index, w] of workout.workout_exercises.entries()) {
        const workoutExercise = w.workout_exercises_id;

        if (!workoutExercise) {
          throw new Error("Missing workoutExercise in WorkoutSequence");
        }

        const exercise = workoutExercise.exercise;

        if (!exercise) {
          throw new Error("Missing exercise in WorkoutSequence");
        }

        if (!exercise.video || typeof exercise.video !== "string") {
          throw new Error("Missing video in WorkoutSequence");
        }

        const exerciseVideoId = exercise.video;
        const url = `https://api.pathura.com/assets/${exerciseVideoId}?access_token=${props.access_token}`;

        const { free, waitUntilDone } = prefetch(url, {
          method: "blob-url",
        });

        try {
          const blobUrl = await waitUntilDone();
          const preparedSet = {
            workoutExercise: workoutExercise,
            prefetchedUrl: blobUrl,
            nativeUrl: url,
            startFrame: 0,
            count: index + 1,
          };

          //
          if (workoutExercise.is_reps) {
            const sets = workoutExercise.sets || 3;
            for (let i = 0; i < sets; i++) {
              preparedItems.push({
                ...(preparedSet as any),
                setsItemIndex: i,
              });
            }
          } else {
            preparedItems.push({ ...(preparedSet as any) });
          }

          unmountCallbacksCollection.push(free);
        } catch (error) {
          console.log({
            message: "Error during waitUntilDone initializeSequences",
            log: error,
          });
          console.error(`Error during initializeSequences: ${error}`);
        }
      }

      // INTRO
      let startFrameBaseInSeconds = 0;

      const introMetaData =
        props.introPath && (await getVideoMetadata(props.introPath));

      if (introMetaData && props.introPath) {
        startFrameBaseInSeconds = introMetaData.durationInSeconds;

        const { free, waitUntilDone } = prefetch(props.introPath, {
          method: "blob-url",
        });

        try {
          const blobUrl = await waitUntilDone();
          const preparedIntro = {
            prefetchedUrl: blobUrl,
            startFrame: 0,
            durationInSeconds: introMetaData.durationInSeconds,
          };

          setIntroSequence(preparedIntro);
        } catch (error) {
          console.log({
            message: "Error during waitUntilDone introSequence",
            log: error,
          });
          console.error(`Error during introSequence: ${error}`);
        }
      }

      const outroMetaData =
        props.outroPath && (await getVideoMetadata(props.outroPath));

      if (outroMetaData && props.outroPath) {
        const { free, waitUntilDone } = prefetch(props.outroPath, {
          method: "blob-url",
        });

        try {
          const blobUrl = await waitUntilDone();
          const preparedOutro = {
            prefetchedUrl: blobUrl,
            startFrame:
              props.durationInFrames -
              outroMetaData.durationInSeconds * fps +
              fps,
            durationInSeconds: outroMetaData.durationInSeconds,
          };

          setOutroSequence(preparedOutro);
        } catch (error) {
          console.log({
            message: "Error during waitUntilDone outroSequence",
            log: error,
          });
          console.error(`Error during outroSequence: ${error}`);
        }
      }

      for await (const [index] of preparedItems.entries()) {
        preparedItems[index].startFrame = await preparedItems
          .slice(0, index)
          .reduce(async (acc: Promise<number>, curr) => {
            const accumulator = await acc;
            const workoutExercise = curr.workoutExercise;

            if (workoutExercise.is_reps) {
              const exercise = workoutExercise.exercise;

              if (!exercise || typeof exercise !== "object") {
                throw new Error("Missing exercise in WorkoutSequence");
              }

              if (!exercise.video || typeof exercise.video !== "string") {
                throw new Error("Missing video in WorkoutSequence");
              }

              const exerciseVideoId = exercise.video;
              const url = `https://api.pathura.com/assets/${exerciseVideoId}?access_token=${props.access_token}`;

              const exerciseMetadata = await getVideoMetadata(url).catch(
                (error) => console.log(error)
              );

              if (!exerciseMetadata) {
                throw new Error(
                  "Error in get exerciseMetadata in initializeSequences > Composition.tsx"
                );
              }

              if (!workoutExercise.playback) {
                throw new Error(
                  "Missing playback in initializeSequences > Composition.tsx"
                );
              }

              const unitDurationWithPlayback = getPlayback(
                workoutExercise.playback,
                exerciseMetadata.durationInSeconds
              );
              const reps = workoutExercise.reps || 12;

              const repsDurationWithPlayback =
                unitDurationWithPlayback.durationInSeconds * reps;

              if (!curr.workoutExercise.prepare_duration) {
                throw new Error(
                  "Missing prepare_duration in initializeSequences > Composition.tsx"
                );
              }

              const sequenceDuration =
                repsDurationWithPlayback +
                curr.workoutExercise.prepare_duration;

              // override values
              /* preparedItems[index - 1].workoutExercise.duration =
                repsDurationWithPlayback; */

              return Math.floor(accumulator + sequenceDuration * fps);
            } else {
              const duration = curr.workoutExercise.duration || 60;

              if (!curr.workoutExercise.prepare_duration) {
                throw new Error(
                  "Missing prepare_duration in initializeSequences > Composition.tsx"
                );
              }

              const sequenceDuration =
                duration + curr.workoutExercise.prepare_duration;

              return Math.floor(accumulator + sequenceDuration * fps);
            }
          }, Promise.resolve(Math.floor(startFrameBaseInSeconds * fps)));
      }

      for await (const [index, value] of preparedItems.entries()) {
        const workoutExercise = value.workoutExercise;
        if (workoutExercise.is_reps) {
          const exercise = workoutExercise.exercise;

          if (!exercise || typeof exercise !== "object") {
            throw new Error("Missing exercise in WorkoutSequence");
          }

          if (!exercise.video || typeof exercise.video !== "string") {
            throw new Error("Missing video in WorkoutSequence");
          }

          const exerciseVideoId = exercise.video;
          const url = `https://api.pathura.com/assets/${exerciseVideoId}?access_token=${props.access_token}`;

          const exerciseMetadata = await getVideoMetadata(url).catch((error) =>
            console.log(error)
          );

          if (!exerciseMetadata) {
            throw new Error(
              "Error in get exerciseMetadata in initializeSequences > Composition.tsx"
            );
          }

          if (!workoutExercise.playback) {
            throw new Error(
              "Missing playback in initializeSequences > Composition.tsx"
            );
          }

          const unitDurationWithPlayback = getPlayback(
            workoutExercise.playback,
            exerciseMetadata.durationInSeconds
          );
          const reps = workoutExercise.reps || 12;
          const repsDurationWithPlayback =
            unitDurationWithPlayback.durationInSeconds * reps;

          // override values
          preparedItems[index].workoutExercise.duration =
            repsDurationWithPlayback;
        }
      }

      /**
       * Set states
       */
      setSequences(preparedItems);
      setUnmountCallbacks(unmountCallbacksCollection);
      console.log({ message: "Sequences initialized", log: undefined });
      continueRender(handleVideoInitialize);
    } catch (error) {
      console.log({
        message: "Error in initializeSequences, rendering cancel",
        log: error,
      });
      cancelRender(error);
    }
  }, []);

  /**
   * Prefetch all videos for workout
   */
  useEffect(() => {
    const workout = props.workout;
    if (!workout) throw new Error("Workout not found");

    const workoutService = new WorkoutService(props.access_token);
    setWorkoutService(workoutService);
    initializeAudioSequences(workout);
    initializeSequences(workout);

    console.log({
      message: "Prefetch for all Videos is started",
      log: undefined,
    });

    /**
     * Free all prefetched items when component unmounts
     */
    return () => {
      if (unmountCallbacks && unmountCallbacks.length > 0) {
        unmountCallbacks.forEach((callback) => {
          callback();
        });
      }
      if (unmountAudioCallbacks && unmountAudioCallbacks.length > 0) {
        unmountAudioCallbacks.forEach((callback) => {
          callback();
        });
      }
    };
  }, []);

  /**
   * Avoid render if any of the following conditions are true:
   */
  if (
    (isAudio && musicLoopDurationInSeconds === 0) ||
    (isAudio && audioSequences.length === 0) ||
    sequences.length === 0
  )
    return;

  /**
   * Render
   */
  return (
    <AbsoluteFill className="bg-white items-center justify-center">
      {/* INTRO */}
      {introSequence && (
        <Animated
          style={{ width: "100%", height: "100%", left: 0, top: 0 }}
          absolute
          animations={[
            Fade({
              to: 0,
              initial: 1,
              start: Math.floor(introSequence.durationInSeconds * fps) - 15,
              duration: 15,
            }),
          ]}
        >
          <Sequence
            from={introSequence.startFrame}
            durationInFrames={introSequence.durationInSeconds * fps}
          >
            <OffthreadVideo src={introSequence.prefetchedUrl}></OffthreadVideo>
          </Sequence>
        </Animated>
      )}

      {/* EXERCISES */}
      {introSequence && outroSequence && (
        <ExerciseSequences
          workout={props.workout}
          sequences={sequences}
          introDurationInSeconds={introSequence.durationInSeconds}
          outroDurationInSeconds={outroSequence.durationInSeconds}
          isCountdownMale={!!props.workout.countdown_male}
        />
      )}

      {/* OUTRO */}
      {outroSequence && (
        <Animated
          style={{ width: "100%", height: "100%", left: 0, top: 0 }}
          absolute
          animations={[
            Fade({
              to: 1,
              initial: 0,
              start: outroSequence.startFrame,
              duration: 15,
            }),
          ]}
        >
          <Sequence
            from={outroSequence.startFrame}
            durationInFrames={outroSequence.durationInSeconds * fps}
          >
            <OffthreadVideo src={outroSequence.prefetchedUrl}></OffthreadVideo>
          </Sequence>
        </Animated>
      )}

      {/* AUDIO */}
      {isAudio && (
        <LoopedAudioSequences
          props={props}
          audioSequences={audioSequences}
          durationInFrames={props.durationInFrames}
        />
      )}
    </AbsoluteFill>
  );
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }

import { InputProps } from "@/remotion/Root";
import { WorkoutCompositionProps } from "./Composition";
import { staticFile } from "remotion";
import { WorkoutService } from "@/remotion/services/workout.service";
import { getVideoMetadata } from "@remotion/media-utils";
import { getWorkoutDurationInSeconds } from "./utils/workout-duration.util";
import { Channels } from "@/types/directus";

export const prepareWorkoutCompositionProps = async (
  inputProps: InputProps
): Promise<WorkoutCompositionProps> => {
  const workoutService = new WorkoutService(inputProps.access_token);

  // TODO: check this again
  const parsedWorkoutId = parseInt(inputProps.id);
  const workout = await workoutService.getWorkout(parsedWorkoutId);

  console.log(workout);

  if (!workout) throw new Error("Workout not found");

  // default workout without intro/outro
  const durationInSeconds = Math.floor(
    await getWorkoutDurationInSeconds(workout, inputProps.access_token)
  );

  const channel = (workout!.channel as Channels).channel_id;

  let introUrl: any;
  let outroUrl: any;

  switch (channel) {
    case "HomeGymMen":
      introUrl = staticFile("workouts/homegymmen/intro.mp4");
      outroUrl = staticFile("workouts/homegymmen/outro.mp4");
      break;
    case "HomeGymWomen":
      introUrl = staticFile("workouts/homegymwomen/intro.mp4");
      outroUrl = staticFile("workouts/homegymwomen/outro.mp4");
      break;
    case "WORKOUT-PATH":
    default:
      introUrl = staticFile("workouts/workoutpath/intro.mp4");
      outroUrl = staticFile("workouts/workoutpath/outro.mp4");
      break;
  }

  // calculate intro & outro
  const introMetadata =
    introUrl &&
    (await getVideoMetadata(introUrl).catch((error) => console.log(error)));
  const outroMetadata =
    outroUrl &&
    (await getVideoMetadata(outroUrl).catch((error) => console.log(error)));

  const introDurationInSeconds =
    (introMetadata && Math.floor(introMetadata.durationInSeconds)) || 0;
  const outroDurationInSeconds =
    (outroMetadata && Math.floor(outroMetadata.durationInSeconds)) || 0;

  // intro + workout + outro
  const completeDurationInSeconds =
    durationInSeconds + introDurationInSeconds + outroDurationInSeconds;

  const props: WorkoutCompositionProps = {
    ...inputProps,
    workout: workout,
    durationInSeconds: completeDurationInSeconds,
    durationInFrames: completeDurationInSeconds * 30,
    introPath: introUrl,
    outroPath: outroUrl,
  };

  return props;
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }

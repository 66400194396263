// animations.js
import React, { ReactNode, useMemo } from "react";
import {
  interpolate,
  useCurrentFrame,
  useVideoConfig,
  Easing,
  random,
} from "remotion";
import { noise2D } from "@remotion/noise";

interface MosaicProps {
  children: ReactNode;
}

interface ZoomProps {
  duration: number;
  children: ReactNode;
  mosaic?: boolean;
}

interface SwayInProps {
  duration: number;
  children: ReactNode;
  mosaic?: boolean;
}

interface PendulumProps {
  duration: number;
  children: ReactNode;
  direction?: "left" | "right";
  mosaic?: boolean;
}

interface TrainProps {
  duration: number;
  children: ReactNode;
  direction?: "left" | "right";
  mosaic?: boolean;
}

interface FallProps {
  duration: number;
  children: ReactNode;
  direction?: "left" | "right" | "down";
  mosaic?: boolean;
}

interface BounceProps {
  duration: number;
  children: ReactNode;
  direction: "up" | "down" | "left" | "right";
  mosaic?: boolean;
}

interface WobbleProps {
  duration: number;
  children: ReactNode;
  mosaic?: boolean;
}

interface RiseProps {
  duration: number;
  children: ReactNode;
  direction?: "left" | "right" | "down" | "up";
  mosaic?: boolean;
}

interface Zoom2Props {
  duration: number;
  children: ReactNode;
  mosaic?: boolean;
}

interface SpinProps {
  duration: number;
  children: ReactNode;
  type: "clockwise" | "counter-clockwise" | "boomerang";
  mosaic?: boolean;
}

interface KenBurnsProps {
  duration: number;
  children: ReactNode;
  mosaic?: boolean;
}

interface WithAnimationProps {
  fps: number;
  media: {
    animations?: {
      combo?: string;
      mosaic?: boolean;
    };
  };
  duration: number;
  children: ReactNode;
}

// Helper Functions and Constants
const easeIn = Easing.bezier(0.06, 1, 0.04, 0.99);
const easeOut = Easing.bezier(0.94, 0, 0.96, 0.01);

const toFixed = (num: number, fixed = 2) => +num.toFixed(fixed);

/* const wiggle = (
  freq: number,
  amplitude: number,
  octaves = 1,
  decay = 0.5,
  time = 0,
  seed = "seed"
) => {
  let result = 0;
  let amp = amplitude;
  let f = freq;
  for (let i = 0; i < octaves; i++) {
    result += random(`${seed}-${i}`, time * f) * amp;
    amp *= decay;
    f *= 2;
  }
  return result;
}; */

const wiggle = (
  freq: number,
  amplitude: number,
  octaves = 1,
  decay = 0.5,
  time: number,
  seed: any
) => {
  let result = 0;
  let amp = amplitude;
  let f = freq;
  for (let i = 0; i < octaves; i++) {
    // Verwenden Sie time * f für x und seed + i für y, um konsistente Werte zu erzielen
    result += noise2D(seed, time * f, i) * amp;
    amp *= decay;
    f *= 2; // Verdoppeln der Frequenz für höhere Oktaven
  }
  return result;
};

const getWiggleTransform = (time: number) => {
  const tx = wiggle(0.2, 50, 2, 1, time, "txseed");
  const ty = wiggle(0.2, 50, 2, 1, time, "tyseed");
  const angle = wiggle(0.2, 15, 2, 1, time, "angleseed");
  return { tx, ty, angle };
};

// Mosaic Component
export const Mosaic: React.FC<MosaicProps> = ({ children }) => (
  <>
    {[
      { x: -100, y: 0, scaleX: -1, scaleY: 1 },
      { x: 0, y: 0, scaleX: 1, scaleY: 1 },
      { x: 100, y: 0, scaleX: -1, scaleY: 1 },
      { x: 0, y: -100, scaleX: 1, scaleY: -1 },
      { x: 0, y: 100, scaleX: 1, scaleY: -1 },
      { x: -100, y: -100, scaleX: -1, scaleY: -1 },
      { x: 100, y: -100, scaleX: -1, scaleY: -1 },
      { x: -100, y: 100, scaleX: -1, scaleY: -1 },
      { x: 100, y: 100, scaleX: -1, scaleY: -1 },
    ].map((transform) => (
      <div
        key={`${transform.x}-${transform.y}`}
        style={{
          overflow: "hidden",
          position: "absolute",
          inset: 0,
          width: "100%",
          height: "100%",
          transform: `
            translate(${transform.x}%, ${transform.y}%)
            scale(${1.01 * transform.scaleX}, ${1.01 * transform.scaleY})
          `,
        }}
      >
        {children}
      </div>
    ))}
  </>
);

// Zoom Animation
export const Zoom: React.FC<ZoomProps> = ({ duration, children, mosaic }) => {
  const { fps } = useVideoConfig();
  const frame = useCurrentFrame();

  const zoomInScale = useZoomInEnter();
  const { tx, ty, angle } = getWiggleTransform(frame / fps);
  const zoomOutScale = useZoomInExit(duration);

  const transform = `translateX(${tx}px) translateY(${ty}px) rotate(${angle}deg) scale(${
    zoomInScale * zoomOutScale
  })`;

  return (
    <div style={{ position: "absolute", inset: 0, transform }}>
      {mosaic ? <Mosaic>{children}</Mosaic> : children}
    </div>
  );
};

const useZoomInEnter = () => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const duration = toFixed(0.6 * fps);
  const progress = interpolate(frame, [0, duration], [2, 1], {
    extrapolateRight: "clamp",
    extrapolateLeft: "clamp",
    easing: easeIn,
  });
  return progress;
};

const useZoomInExit = (duration: number) => {
  const halfDuration = toFixed(0.5 * duration);
  const frame = useCurrentFrame();
  const progress = interpolate(frame, [halfDuration, duration], [1, 4], {
    extrapolateRight: "clamp",
    extrapolateLeft: "clamp",
    easing: easeOut,
  });
  return progress;
};

// Sway In Animation
export const SwayIn: React.FC<SwayInProps> = ({
  duration,
  children,
  mosaic,
}) => {
  const { fps } = useVideoConfig();
  const frame = useCurrentFrame();

  const { angle: rotateAngle, scale } = useRotateEnter();
  const { tx, ty, angle: wiggleAngle } = getWiggleTransform(frame / fps);
  const zoomOutScale = useZoomInExit(duration);

  const transform = `
    translateX(${tx}px)
    translateY(${ty}px)
    rotate(${wiggleAngle + rotateAngle}deg)
    scale(${zoomOutScale * scale})
  `;

  return (
    <div style={{ position: "absolute", inset: 0, transform }}>
      {mosaic ? <Mosaic>{children}</Mosaic> : children}
    </div>
  );
};

const useRotateEnter = () => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const duration = toFixed(0.6 * fps);

  const progress = interpolate(frame, [0, duration], [0, 1], {
    extrapolateRight: "clamp",
    extrapolateLeft: "clamp",
    easing: easeIn,
  });
  const direction = random("rotate") > 0.5 ? 1 : -1;
  const angle = interpolate(progress, [0, 1], [90 * direction, 0]);
  const scale = interpolate(progress, [0, 1], [2, 1]);

  return { angle, scale };
};

// Pendulum Animation
export const Pendulum: React.FC<PendulumProps> = ({
  duration,
  children,
  direction = "right",
  mosaic,
}) => {
  const frame = useCurrentFrame();
  const halfDuration = toFixed(0.5 * duration);

  const translateFrom = {
    left: [-50, 0],
    right: [50, 0],
  };

  const moveIn = interpolate(
    frame,
    [0, halfDuration],
    translateFrom[direction],
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
      easing: easeIn,
    }
  );

  const rotate = interpolate(
    frame,
    [0, duration],
    direction === "left" ? [5, -5] : [-5, 5],
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
    }
  );

  const moveOut = interpolate(
    frame,
    [halfDuration, duration],
    translateFrom[direction].reverse(),
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
      easing: easeOut,
    }
  );

  const transform = `translate(${moveIn + moveOut}%, 0) rotate(${rotate}deg)`;

  return (
    <div
      data-animation="slide"
      style={{ position: "absolute", inset: 0, transform }}
    >
      {mosaic ? <Mosaic>{children}</Mosaic> : children}
    </div>
  );
};

// Train Animation
export const Train: React.FC<TrainProps> = ({
  duration,
  children,
  direction = "right",
  mosaic,
}) => {
  const frame = useCurrentFrame();
  const halfDuration = toFixed(0.5 * duration);

  const translateFrom = {
    right: {
      from: [-80, 0],
      to: [0, 80],
    },
    left: {
      from: [80, 0],
      to: [0, -80],
    },
  };

  const moveIn = interpolate(
    frame,
    [0, halfDuration],
    translateFrom[direction].from,
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
      easing: easeIn,
    }
  );

  const scale = interpolate(frame, [0, duration], [1, 0.9], {
    extrapolateRight: "clamp",
    extrapolateLeft: "clamp",
  });

  const moveOut = interpolate(
    frame,
    [halfDuration, duration],
    translateFrom[direction].to,
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
      easing: easeOut,
    }
  );

  const transform = `translate(${moveIn + moveOut}%, 0) scale(${scale})`;

  return (
    <div
      data-animation="slide"
      style={{ position: "absolute", inset: 0, transform }}
    >
      {mosaic ? <Mosaic>{children}</Mosaic> : children}
    </div>
  );
};

// Helper functions for Fall, Bounce, Rise, etc.
const composeTransforms = (transforms: any) => transforms.join(" ");

const appearFall = (frame: number, duration: number) => {
  const translateY = interpolate(
    frame,
    [0, toFixed(0.5 * duration)],
    [-60, 0],
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
      easing: easeIn,
    }
  );
  return `translateY(${translateY}%)`;
};

const duringScaleDown = (frame: number, duration: number) => {
  const scale = interpolate(frame, [0, duration], [1, 0.8], {
    extrapolateRight: "clamp",
    extrapolateLeft: "clamp",
    easing: Easing.ease,
  });
  return `scale(${scale})`;
};

const disappearLeft = (frame: number, duration: number) => {
  const translateX = interpolate(
    frame,
    [toFixed(0.5 * duration), duration],
    [0, -80],
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
      easing: easeOut,
    }
  );
  return `translateX(${translateX}%)`;
};

const disappearRight = (frame: number, duration: number) => {
  const translateX = interpolate(
    frame,
    [toFixed(0.5 * duration), duration],
    [0, 80],
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
      easing: easeOut,
    }
  );
  return `translateX(${translateX}%)`;
};

const disappearDown = (frame: number, duration: number) => {
  const translateY = interpolate(
    frame,
    [toFixed(0.5 * duration), duration],
    [0, 80],
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
      easing: easeOut,
    }
  );
  return `translateY(${translateY}%)`;
};

const disappearUp = (frame: number, duration: number) => {
  const translateY = interpolate(
    frame,
    [toFixed(0.5 * duration), duration],
    [0, -80],
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
      easing: easeOut,
    }
  );
  return `translateY(${translateY}%)`;
};

const appearBounce = (frame: number, duration: number) => {
  const scale = interpolate(frame, [0, toFixed(0.5 * duration)], [0.6, 1], {
    extrapolateRight: "clamp",
    extrapolateLeft: "clamp",
    easing: Easing.elastic(3),
  });
  return `scale(${scale})`;
};

const duringWiggle = (frame: number, duration: number, fps: number) => {
  const { tx, ty, angle } = getWiggleTransform(frame / fps);
  return [`translateX(${tx}px)`, `translateY(${ty}px)`, `rotate(${angle}deg)`];
};

const disappearZoomIn = (frame: number, duration: number) => {
  const scale = interpolate(
    frame,
    [toFixed(0.5 * duration), duration],
    [1, 10],
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
      easing: easeOut,
    }
  );
  return `scale(${scale})`;
};

const appearRise = (frame: number, duration: number) => {
  const translateY = interpolate(frame, [0, toFixed(0.5 * duration)], [60, 0], {
    extrapolateRight: "clamp",
    extrapolateLeft: "clamp",
    easing: easeIn,
  });
  return `translateY(${translateY}%)`;
};

// Fall Animation
export const Fall: React.FC<FallProps> = ({
  duration,
  children,
  direction = "right",
  mosaic,
}) => {
  const frame = useCurrentFrame();

  const disappearFunctions = {
    left: disappearLeft,
    right: disappearRight,
    down: disappearDown,
  };

  const appearTransform = appearFall(frame, duration);
  const duringTransform = duringScaleDown(frame, duration);
  const disappearTransform = disappearFunctions[direction](frame, duration);

  const transform = composeTransforms([
    appearTransform,
    duringTransform,
    disappearTransform,
  ]);

  return (
    <div
      data-animation="fall"
      style={{ position: "absolute", inset: 0, transform }}
    >
      {mosaic ? <Mosaic>{children}</Mosaic> : children}
    </div>
  );
};

// Bounce Animation
export const Bounce: React.FC<BounceProps> = ({
  duration,
  children,
  direction,
  mosaic,
}) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const disappearFunctions = {
    up: disappearUp,
    left: disappearLeft,
    right: disappearRight,
    down: disappearDown,
  };

  const appearTransform = appearBounce(frame, duration);
  const duringTransforms = duringWiggle(frame, duration, fps);
  const disappearTransform = disappearFunctions[direction](frame, duration);

  const transform = composeTransforms([
    appearTransform,
    ...duringTransforms,
    disappearTransform,
  ]);

  return (
    <div
      data-animation="fall"
      style={{ position: "absolute", inset: 0, transform }}
    >
      {mosaic ? <Mosaic>{children}</Mosaic> : children}
    </div>
  );
};

// Wobble Animation
export const Wobble: React.FC<WobbleProps> = ({
  duration,
  children,
  mosaic,
}) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const appearTransform = appearRightWobble(frame, duration);
  const duringTransforms = duringWiggle(frame, duration, fps);
  const disappearTransform = disappearZoomIn(frame, duration);

  const transform = composeTransforms([
    ...appearTransform,
    ...duringTransforms,
    disappearTransform,
  ]);

  return (
    <div
      data-animation="fall"
      style={{ position: "absolute", inset: 0, transform }}
    >
      {mosaic ? <Mosaic>{children}</Mosaic> : children}
    </div>
  );
};

const appearRightWobble = (frame: number, duration: number) => {
  const translateX = interpolate(frame, [0, toFixed(duration)], [-20, 0], {
    extrapolateRight: "clamp",
    extrapolateLeft: "clamp",
    easing: Easing.elastic(4),
  });
  const rotate = interpolate(frame, [0, toFixed(duration)], [5, 0], {
    extrapolateRight: "clamp",
    extrapolateLeft: "clamp",
    easing: Easing.elastic(4),
  });
  return [`translateX(${translateX}%)`, `rotate(${rotate}deg)`];
};

// Rise Animation
export const Rise: React.FC<RiseProps> = ({
  duration,
  children,
  direction = "right",
  mosaic,
}) => {
  const frame = useCurrentFrame();

  const disappearFunctions = {
    left: disappearLeft,
    right: disappearRight,
    down: disappearDown,
    up: disappearUp,
  };

  const appearTransform = appearRise(frame, duration);
  const duringTransform = duringScaleDown(frame, duration);
  const disappearTransform = disappearFunctions[direction](frame, duration);

  const transform = composeTransforms([
    appearTransform,
    duringTransform,
    disappearTransform,
  ]);

  return (
    <div
      data-animation="fall"
      style={{ position: "absolute", inset: 0, transform }}
    >
      {mosaic ? <Mosaic>{children}</Mosaic> : children}
    </div>
  );
};

// Zoom2 Animation
export const Zoom2: React.FC<Zoom2Props> = ({ duration, children, mosaic }) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const appearTransform = appearZoomIn(frame, duration);
  const duringTransforms = duringWiggle(frame, duration, fps);
  const disappearTransform = disappearZoomOut(frame, duration);

  const transform = composeTransforms([
    appearTransform,
    ...duringTransforms,
    disappearTransform,
  ]);

  return (
    <div
      data-animation="fall"
      style={{ position: "absolute", inset: 0, transform }}
    >
      {mosaic ? <Mosaic>{children}</Mosaic> : children}
    </div>
  );
};

const appearZoomIn = (frame: number, duration: number) => {
  const scale = interpolate(frame, [0, toFixed(0.5 * duration)], [0.4, 1], {
    extrapolateRight: "clamp",
    extrapolateLeft: "clamp",
    easing: easeIn,
  });
  return `scale(${scale})`;
};

const disappearZoomOut = (frame: number, duration: number) => {
  const scale = interpolate(
    frame,
    [toFixed(0.5 * duration), duration],
    [1, 0.2],
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
      easing: easeOut,
    }
  );
  return `scale(${scale})`;
};

// Spin Animation
export const Spin: React.FC<SpinProps> = ({
  duration,
  children,
  type,
  mosaic,
}) => {
  const frame = useCurrentFrame();

  const spinFunctions = {
    clockwise: [appearSpinClockwise, disappearSpinClockwise],
    "counter-clockwise": [
      appearSpinCounterClockwise,
      disappearSpinCounterClockwise,
    ],
    boomerang: [appearSpinClockwise, disappearSpinCounterClockwise],
  };

  const [appearSpin, disappearSpin] = spinFunctions[type];

  const appearTransform = appearSpin(frame, duration);
  const duringTransform = duringZoomIn(frame, duration);
  const disappearTransform = disappearSpin(frame, duration);

  const transform = composeTransforms([
    appearTransform,
    duringTransform,
    disappearTransform,
  ]);

  return (
    <div
      data-animation="fall"
      style={{ position: "absolute", inset: 0, transform }}
    >
      {mosaic ? <Mosaic>{children}</Mosaic> : children}
    </div>
  );
};

const appearSpinClockwise = (frame: number, duration: number) => {
  const rotate = interpolate(frame, [0, toFixed(0.5 * duration)], [-90, 0], {
    extrapolateRight: "clamp",
    extrapolateLeft: "clamp",
    easing: easeIn,
  });
  return `rotate(${rotate}deg)`;
};

const disappearSpinClockwise = (frame: number, duration: number) => {
  const rotate = interpolate(
    frame,
    [toFixed(0.5 * duration), duration],
    [0, 90],
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
      easing: easeOut,
    }
  );
  return `rotate(${rotate}deg)`;
};

const appearSpinCounterClockwise = (frame: number, duration: number) => {
  const rotate = interpolate(frame, [0, toFixed(0.5 * duration)], [90, 0], {
    extrapolateRight: "clamp",
    extrapolateLeft: "clamp",
    easing: easeIn,
  });
  return `rotate(${rotate}deg)`;
};

const disappearSpinCounterClockwise = (frame: number, duration: number) => {
  const rotate = interpolate(
    frame,
    [toFixed(0.5 * duration), duration],
    [0, -90],
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
      easing: easeOut,
    }
  );
  return `rotate(${rotate}deg)`;
};

const duringZoomIn = (frame: number, duration: number) => {
  const scale = interpolate(frame, [0, duration], [1, 1.2], {
    extrapolateRight: "clamp",
    extrapolateLeft: "clamp",
    easing: Easing.ease,
  });
  return `scale(${scale})`;
};

// Ken Burns Effect
export const KenBurns: React.FC<KenBurnsProps> = ({
  duration,
  children,
  mosaic,
}) => {
  const frame = useCurrentFrame();
  const scale = interpolate(frame, [0, duration], [1, 1.1], {
    extrapolateRight: "clamp",
    extrapolateLeft: "clamp",
    easing: Easing.linear,
  });

  return (
    <div
      style={{
        position: "absolute",
        inset: 0,
        width: "100%",
        height: "100%",
        transform: `scale(${scale})`,
      }}
    >
      {mosaic ? <Mosaic>{children}</Mosaic> : children}
    </div>
  );
};
/* interface ZoomBlurProps {
  duration: number;
  strength?: number;
  children: ReactNode;
}

export const ZoomBlur: React.FC<ZoomBlurProps> = ({
  duration,
  strength = 0.5,
  children,
}) => {
  const frame = useCurrentFrame();

  const blur = interpolate(frame, [0, duration], [0, strength], {
    extrapolateRight: "clamp",
  });

  const filter = `blur(${blur}px)`;

  const scale = interpolate(frame, [0, duration], [1, 1.2], {
    extrapolateRight: "clamp",
  });

  return (
    <div
      style={{
        filter,
        transform: `scale(${scale})`,
        width: "100%",
        height: "100%",
      }}
    >
      {children}
    </div>
  );
}; */

export const WithAnimation: React.FC<WithAnimationProps> = ({
  fps,
  media,
  duration,
  children,
}) => {
  const { animations } = media;

  switch (animations?.combo) {
    case "sway-in":
      return (
        <SwayIn duration={duration} mosaic={animations.mosaic}>
          {children}
        </SwayIn>
      );
    case "zoom":
      return (
        <Zoom duration={duration} mosaic={animations.mosaic}>
          {children}
        </Zoom>
      );
    case "zoom2":
      return (
        <Zoom2 duration={duration} mosaic={animations.mosaic}>
          {children}
        </Zoom2>
      );
    case "pendulum-left":
      return (
        <Pendulum
          duration={duration}
          direction="left"
          mosaic={animations.mosaic}
        >
          {children}
        </Pendulum>
      );
    case "pendulum-right":
      return (
        <Pendulum
          duration={duration}
          direction="right"
          mosaic={animations.mosaic}
        >
          {children}
        </Pendulum>
      );
    case "train-left":
      return (
        <Train duration={duration} direction="left" mosaic={animations.mosaic}>
          {children}
        </Train>
      );
    case "train-right":
      return (
        <Train duration={duration} direction="right" mosaic={animations.mosaic}>
          {children}
        </Train>
      );
    case "fall-left":
      return (
        <Fall duration={duration} direction="left" mosaic={animations.mosaic}>
          {children}
        </Fall>
      );
    case "fall-right":
      return (
        <Fall duration={duration} direction="right" mosaic={animations.mosaic}>
          {children}
        </Fall>
      );
    case "bounce-up":
      return (
        <Bounce duration={duration} direction="up" mosaic={animations.mosaic}>
          {children}
        </Bounce>
      );
    case "bounce-down":
      return (
        <Bounce duration={duration} direction="down" mosaic={animations.mosaic}>
          {children}
        </Bounce>
      );
    case "wobble":
      return (
        <Wobble duration={duration} mosaic={animations.mosaic}>
          {children}
        </Wobble>
      );
    case "rise-up":
      return (
        <Rise duration={duration} direction="up" mosaic={animations.mosaic}>
          {children}
        </Rise>
      );
    case "spin-clockwise":
      return (
        <Spin duration={duration} type="clockwise" mosaic={animations.mosaic}>
          {children}
        </Spin>
      );
    case "ken-burns":
      return (
        <KenBurns duration={duration} mosaic={animations.mosaic}>
          {children}
        </KenBurns>
      );
    default:
      return (
        <>
          {/* Default or no animation */}
          {children}
        </>
      );
  }
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }

import {
  Composition,
  delayRender,
  continueRender,
  getInputProps,
  cancelRender,
} from "remotion";
import {
  WorkoutCompositionProps,
  WorkoutComposition,
} from "./compositions/Workouts/Composition";
import "./styles/global.css";
import React, { useEffect, useState } from "react";
import {
  ReelsComposition,
  ReelsCompositionProps,
} from "./compositions/Reels/Composition";
import { getQueryInputProps } from "./compositions/Workouts/utils/get-query-input-props.util";
import { prepareWorkoutCompositionProps } from "./compositions/Workouts/prepareProps";
import { prepareReelsCompositionProps } from "./compositions/Reels/prepareProps";

export type InputProps = {
  id: string;
  compositionId: "WorkoutComposition" | "ReelsComposition";
  access_token: string;
};

// Input props
const inputProps: InputProps = getInputProps();

export const Root: React.FC = () => {
  const [props, setProps] = useState<
    WorkoutCompositionProps | ReelsCompositionProps | undefined
  >();
  const [handleInitializeProps] = useState(() =>
    delayRender("Initialize Props", {
      timeoutInMilliseconds: 30000,
    })
  );

  useEffect(() => {
    const initializeProps = async () => {
      const queryInputProps = getQueryInputProps();

      // TODO: prüfen ob genauer geprüft werden soll
      const finalInputProps = queryInputProps ?? inputProps;

      switch (finalInputProps.compositionId) {
        case "ReelsComposition":
          const reelsCompositionProps = await prepareReelsCompositionProps(
            finalInputProps
          );
          setProps(reelsCompositionProps);
          break;
        case "WorkoutComposition":
          const workoutCompositionProps = await prepareWorkoutCompositionProps(
            finalInputProps
          );
          setProps(workoutCompositionProps);
          break;
        default:
          throw new Error("PROPS ERROR: CompositionId not found");
      }
    };

    try {
      initializeProps();
    } catch (error) {
      console.log(error);
      cancelRender(
        `some error happens in preparation props for preview mode ${JSON.stringify(
          error
        )}`
      );
    }
  }, []);

  useEffect(() => {
    /**
     * Continue Render when props finally initialized
     */
    if (props) {
      continueRender(handleInitializeProps);
    }
  }, [props]);

  if (!props) {
    return <></>;
  }

  if (props.compositionId === "ReelsComposition") {
    return (
      <Composition
        id="ReelsComposition"
        component={ReelsComposition}
        durationInFrames={props.durationInFrames}
        fps={30}
        width={1080}
        height={1920}
        defaultProps={props as ReelsCompositionProps}
      />
    );
  }

  if (props.compositionId === "WorkoutComposition") {
    return (
      <Composition
        id="WorkoutComposition"
        component={WorkoutComposition}
        durationInFrames={props.durationInFrames}
        fps={30}
        width={3840}
        height={2160}
        defaultProps={props as WorkoutCompositionProps}
      />
    );
  }

  return <></>;
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }

import {
  AbsoluteFill,
  Sequence,
  Audio,
  staticFile,
  OffthreadVideo,
  useVideoConfig,
} from "remotion";
import { useMemo } from "react";
import { Animated, Fade, Scale } from "remotion-animated";
import json from "./history-item.json";
import { loadFont } from "@remotion/google-fonts/Bangers";
import { WithAnimation } from "./animations/animations";
import {
  linearTiming,
  springTiming,
  TransitionSeries,
} from "@remotion/transitions";

import { fade } from "@remotion/transitions/fade";
import { FilterImage } from "./animations/experimental/Pixi";
import { InputProps } from "@/remotion/Root";

const { fontFamily } = loadFont();

export type ReelsCompositionProps = InputProps & {
  durationInFrames: number; // important for init in root
};

const animations = [
  "zoom",
  "zoom2",
  "sway-in",
  "pendulum-left",
  "pendulum-right",
  "train-left",
  "train-right",
  "fall-left",
  "fall-right",
  "bounce-up",
  "bounce-down",
  "wobble",
  "rise-up",
  "spin-clockwise",
  "ken-burns",
];

export const ReelsComposition = (props: ReelsCompositionProps) => {
  const { fps, durationInFrames } = useVideoConfig();
  const alignments = json.alignments.normalized_alignment;
  const durationPerAnimation = 5 * fps;

  // Erste Schleife: Berechne Wörter und deren Start- und Endzeiten in Millisekunden
  const wordTimes = useMemo(() => {
    const words = [];
    let currentWord = "";
    let wordStartMs = 0;
    let wordEndMs = 0;

    for (let index = 0; index < alignments.characters.length; index++) {
      const char = alignments.characters[index];
      const startSeconds = alignments.character_start_times_seconds[index];
      const endSeconds = alignments.character_end_times_seconds[index];

      // Umrechnung in Millisekunden
      const startMs = startSeconds * 1000;
      const endMs = endSeconds * 1000;

      if (char.trim()) {
        if (currentWord === "") {
          wordStartMs = startMs; // Setze Startzeit für das neue Wort
        }
        currentWord += char;
        wordEndMs = endMs; // Aktualisiere Endzeit des aktuellen Zeichens
      }

      // Füge das Wort hinzu, wenn ein Leerzeichen oder das Ende des Texts erreicht ist
      if (!char.trim() || index === alignments.characters.length - 1) {
        if (currentWord !== "") {
          words.push({
            word: currentWord,
            startMs: wordStartMs,
            endMs: wordEndMs,
          });
          currentWord = ""; // Zurücksetzen für das nächste Wort
        }
      }
    }

    return words;
  }, [alignments]);

  // Zweite Schleife: Berechne die genauen Frames basierend auf den Millisekunden der Wörter
  const wordAlignments = useMemo(() => {
    return wordTimes.map((word, index) => {
      // Berechne die Start- und Endframes direkt aus den Millisekunden
      const startFrame = (word.startMs / 1000) * fps;
      let endFrame;

      // Wenn es ein nächstes Wort gibt, setze die Endframe auf dessen Startframe
      if (index < wordTimes.length - 1) {
        endFrame = (wordTimes[index + 1].startMs / 1000) * fps;
      } else {
        // Für das letzte Wort, setze die Endframe basierend auf der Wortendzeit
        endFrame = (word.endMs / 1000) * fps;
      }

      // Runde die berechneten Start- und Endframes nur zum Schluss
      const roundedStartFrame = Math.round(startFrame);
      const roundedEndFrame = Math.round(endFrame);
      const durationInFrames = Math.max(1, roundedEndFrame - roundedStartFrame);

      return {
        word: word.word,
        startSeconds: word.startMs / 1000,
        endSeconds: word.endMs / 1000,
        startFrame: roundedStartFrame,
        durationInFrames,
      };
    });
  }, [wordTimes, fps]);

  return (
    <AbsoluteFill className="bg-white items-center justify-center">
      {durationInFrames > 0 && (
        <Sequence
          name="Reels"
          className="flex items-center justify-center"
          from={0}
          durationInFrames={durationInFrames}
        >
          <Audio src={staticFile("reels/reels-demo.wav")} />

          {/* {animations.map((animationName, index) => {
            const media = {
              animations: {
                combo: animationName,
                mosaic: true,
              },
            };

            return (
              <Sequence
                key={animationName}
                from={index * durationPerAnimation}
                durationInFrames={durationPerAnimation}
              >
                <WithAnimation
                  fps={fps}
                  media={media}
                  duration={durationPerAnimation}
                >
                  <div
                    style={{
                      backgroundColor: "#e0e0e0",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: 50,
                    }}
                  >
                    <img
                      src={staticFile(`reels/${index + 1}.webp`)}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </WithAnimation>
              </Sequence>
            );
          })} */}

          <TransitionSeries>
            {animations.map((animationName, index) => {
              const media = {
                animations: {
                  combo: animationName,
                  mosaic: true,
                },
                // Weitere Eigenschaften, falls erforderlich
              };

              return (
                <>
                  <TransitionSeries.Sequence
                    key={animationName}
                    durationInFrames={durationPerAnimation}
                  >
                    <WithAnimation
                      fps={fps}
                      media={media}
                      duration={durationPerAnimation}
                    >
                      <div
                        style={{
                          backgroundColor: "#e0e0e0",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: 50,
                        }}
                      >
                        <img
                          src={staticFile(`reels/${index + 1}.webp`)}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "center center",
                          }}
                        />
                        {/* <FilterImage
                          duration={durationPerAnimation}
                          src={staticFile(`reels/${index + 1}.webp`)}
                        /> */}
                        {/* <OffthreadVideo
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "center center",
                          }}
                          src={staticFile(`reels/tt.mp4`)}
                        ></OffthreadVideo> */}
                      </div>
                    </WithAnimation>
                  </TransitionSeries.Sequence>
                  <TransitionSeries.Transition
                    presentation={fade()}
                    timing={linearTiming({ durationInFrames: 7 })}
                  />
                </>
              );
            })}
          </TransitionSeries>

          {/* Wortweise Textanzeige */}
          <div
            style={{
              fontFamily,
              fontSize: "96px",
              textAlign: "center",
              lineHeight: 1,
            }}
          >
            {wordAlignments.map((alignment, index) => {
              const isLastItem = index === wordAlignments.length - 1;
              const animations = [
                Scale({
                  by: 1.5,
                  initial: 1,
                  mass: 0.5,
                  damping: 7,
                  stiffness: 100,
                  overshootClamping: false,
                }),
              ];

              if (isLastItem) {
                animations.push(
                  Fade({
                    to: 0,
                    start:
                      alignment.startFrame + alignment.durationInFrames - 15,
                  })
                );
              }

              return (
                <Sequence
                  className="w-100 flex items-center justify-center"
                  key={index}
                  from={alignment.startFrame}
                  durationInFrames={
                    isLastItem ? Infinity : alignment.durationInFrames
                  }
                >
                  <Animated
                    animations={animations}
                    style={{
                      maxWidth: "600px",
                      display: "inline-block",
                      margin: "0 10px",
                      color: "white", // Weiße Schrift
                      textShadow: `
                    -5px -5px 0 black,  /* obere linke Ecke */
                    5px -5px 0 black,   /* obere rechte Ecke */
                    -5px 5px 0 black,   /* untere linke Ecke */
                    5px 5px 0 black,    /* untere rechte Ecke */
                    -5px 0px 0 black,   /* links */
                    5px 0px 0 black,    /* rechts */
                    0px -5px 0 black,   /* oben */
                    0px 5px 0 black,    /* unten */
                    /* Zusätzliche Schattenebenen für den normalen Textschatten */
                    0px 0px 20px black,
                    0px 0px 30px black
                  `,
                      fontWeight: "bold",
                      letterSpacing: "3px",
                    }}
                  >
                    {alignment.word}
                  </Animated>
                </Sequence>
              );
            })}
          </div>
        </Sequence>
      )}
    </AbsoluteFill>
  );
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
